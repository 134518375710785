import { useEffect, useState } from "react";
import { UseAuth } from "presentation/hook/auth-hook";
import { RestUseCase } from "data/useCase/rest-usecase";
import { handleRequest } from "main/common/request-common";
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page";
import { IndicatodFilterPlanningAdapter } from "main/adapter/planning/indicator-filter-planning-adapter";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter";
import IndicatorPageV2 from "presentation/pages/analysis/indicator-page-v2";
import { getEnv } from "main/helper/window-helper"

export const IndicatorFactory: React.FC = () => {

    const ENDPOINT_TABLE_PUNCTUATION = getEnv("REACT_APP_END_POINT_INDICATOR")
    const ENDPOINT_PLANNING = getEnv("REACT_APP_END_POINT_INDICATOR") + "/filtros"
    const ENDPOINT_EXCEL= getEnv("REACT_APP_END_POINT_INDICATOR") + "/gerar-excel"

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Análise"
        )[0].navItem.filter((item) => item.title === "Indicador")[0].role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
        updateShowScreen(true);
    }, [loggedUserData]);
    
    const [filial, updateFilial] = useState<SelectOptionInterface[]>([])
    const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>()
    const [base, updateBase] = useState<SelectOptionInterface[]>()
    const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>()
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")!
    const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS")!
    const END_POINT_ITEM_TRACKS = getEnv("REACT_APP_END_POINT_ITEM_TRACKS")!
    const END_POINT_ITEM_BASE = getEnv("REACT_APP_END_POINT_ITEM_BASE")!
    const handleFilial = async () => {
        await new RestUseCase(`${ENDPOINT_FILIAL}?modulos=COMPRAS`).Get().then((response) => {
            updateFilial(
                response.body.map((value: any) => {
                    return { value: value.id, label: value.nome };
                })
            );
        });
    };

    const handleDiscountGroup = async () => {
        await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS)
            .Get()
            .then((response) => {
                updateDiscountGroup(
                    response.body.map((value: any) => {
                        return { value: value, label: value };
                    })
                );
            });
    };
    const handleRangeProfitability = async () => {
        await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
            updateRangeProfitability(
                response.body.map((value: any) => {
                    return { value: value, label: value };
                })
            );
        });
    };
    const handleBase = async () => {
        await new RestUseCase(END_POINT_ITEM_BASE).Get().then((response) => {
            updateBase(
                response.body.bases.map((value: any) => {
                    return { value: value.codigo, label: `${value.codigo} - ${ value.desc}` };
                })
            );
        });
    };
    useEffect(() => {
        handleFilial();
        handleDiscountGroup();
        handleRangeProfitability();
        handleBase();
    }, []);
    useEffect(() => {
        IndicatodFilterPlanningAdapter.map((value) => {
            if (value.label === "Filial") {
                value.field[1].optionsInput = filial;
            }

            if (value.label === "Grupo de desconto") {
                value.field[1].optionsInput = discountGroup;
            }

            if (value.label === "Base") {
                value.field[1].optionsInput = base;
            }

            if (value.label === "Lucratividade do fornecedor") {
                value.field[1].optionsInput = rangeProfitability;
            }
        });

        IndicatodFilterPlanningAdapter.sort(function (a, b) {
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        });
    }, [filial, discountGroup, base, rangeProfitability]);

    const END_POINT_INDICATOR_MONTHS = getEnv("REACT_APP_END_POINT_INDICATOR_MONTHS")!
    const [availableMonths, updateAvailableMonths] = useState<String[]>([]);
    const getAvailableMonths = async () => {
        await new RestUseCase(END_POINT_INDICATOR_MONTHS)
        .Get()
        .then((response) => updateAvailableMonths(response.body));
    };
    useEffect(() => {
        getAvailableMonths();
    }, []);

    return (
        <>
            {showScreen ? (
                <IndicatorPageV2
                    list={async function (params: {
                        type: string;
                        pagination: string;
                        model?: any;
                        metric: string;
                        period: string[];
                        grouping: string;
                        quantity: number;
                        size: number;
                        checkByDateOf: string;
                        filter: any;
                    }): Promise<any> {
                        let url: string = "";

                        if (params.type === "OBS") {
                            url =
                                ENDPOINT_TABLE_PUNCTUATION! +
                                `/${params.checkByDateOf}?quantidade=${params.quantity}&tamanho=${params.size}${params.period}&metrica=${params.metric}`;
                        } else {
                            url =
                                ENDPOINT_TABLE_PUNCTUATION! +
                                `/${params.grouping}?metrica=${params.metric}${params.period}`;
                        }

                        const response = await new RestUseCase(url).Post({
                            data: params.filter,
                        });
                        return response.body;
                    }}
                    listByIdPlanning={async function (): Promise<any> {}}
                    listAllPlanning={async function (): Promise<any> {
                        const response = await new RestUseCase(
                            ENDPOINT_PLANNING!
                        ).Get();
                        return handleRequest(response);
                    }}
                    createPlanning={async function (
                        params?: any
                    ): Promise<any> {
                        params.idEmpresa = loggedUserData?.companyId
                        const response = await new RestUseCase(
                            ENDPOINT_PLANNING!
                        ).Post({ data: params });
                        return handleRequest(response);
                    }}
                    editPlanning={async function (params: {
                        model: any;
                        id: number;
                    }): Promise<any> {
                        params.model.idEmpresa = loggedUserData?.companyId
                        const response = await new RestUseCase(ENDPOINT_PLANNING! + '/' + params.id).Put({ data: params.model });
                        return handleRequest(response);
                    }}
                    removePlanning={async function (
                        params?: number
                    ): Promise<any> {
                        const response = await new RestUseCase(
                            ENDPOINT_PLANNING! + "/" + params
                        ).Delete();
                        return handleRequest(response);
                    }}
                    optionsFiltersDefault={IndicatodFilterPlanningAdapter}
                    exportFileExcel={async function (params: {
                        type: string;
                        pagination: string;
                        model?: any;
                        metric: string;
                        period: string[];
                        grouping: string;
                        quantity: number;
                        size: number;
                        checkByDateOf: string;
                        filter: any;
                    }): Promise<any> {
                        const url =
                                ENDPOINT_EXCEL! +
                                `${params.checkByDateOf}?quantidade=${params.quantity}&tamanho=${params.size}${params.period}&metrica=${params.metric}`;

                        const response = await new RestUseCase(
                            `${url}`
                        ).Post({
                            responseType: "blob"
                        });
                        return handleRequest(response);
                    }}
                    filiais={filial}
                    availableMonths={availableMonths}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
