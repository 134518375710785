import { useEffect, useState } from 'react';
import { UseAuth } from 'presentation/hook/auth-hook';
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page';
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter';
import { getEnv } from 'main/helper/window-helper';
import RegraConsultorOnlinePage from 'presentation/pages/settings/regra-consultor-online-page';
import { Footer } from 'presentation/components/footer/footer-component';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { handleRequest } from 'main/common/request-common';
import { OnlineConsultatntFilterPlanningAdapter } from 'main/adapter/planning/online-consultant-filter-planning-adapter';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { ParseDateToBr } from 'main/helper/format-date-helper';

export const OnlineConsultantFactoryV2: React.FC = () => {
  const [showScreen, updateShowScreen] = useState<boolean>(false);

  const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_ONLINE_CONSULTANT')!;
  const ENDPOINT_PLANNING = getEnv('REACT_APP_END_POINT_ONLINE_CONSULTANT') + '/filtros';

  const END_POINT_ITEM = getEnv('REACT_APP_END_POINT_ITEM');
  const END_POINT_MOVEMENT = getEnv('REACT_APP_END_POINT_MOVEMENT');
  const ENDPOINT_FILIAL = getEnv('REACT_APP_END_POINT_FILIAL')!;
  const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv('REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS')!;
  const END_POINT_ITEM_TRACKS = getEnv('REACT_APP_END_POINT_ITEM_TRACKS')!;
  const END_POINT_ITEM_BASE_DESC = getEnv('REACT_APP_END_POINT_ITEM_BASE_DESC')!;
  const END_POINT_ITEM_BASE_CODE = getEnv('REACT_APP_END_POINT_ITEM_BASE_CODE')!;
  const END_POINT_CORRELATE = getEnv('REACT_APP_END_POINT_CORRELATE_ICON')!;
  const ENDPOINT_COMPANIES_OBSOLETO = getEnv('REACT_APP_END_POINT_COMPANIES_OBSOLETO');
  const ENDPOINT_MANUFACTURER = getEnv('REACT_APP_END_POINT_MANUFACTURER')!;
  const ENDPOINT_CAMPANHAS_ATIVAS = getEnv('REACT_APP_END_POINT_CAMPANHAS_ATIVAS')!;
  const ENDPOINT_LOCATIONS = getEnv('REACT_APP_END_POINT_LOCATIONS');
  
  const titlePage = 'Cadastrar Regras de Consultor Online';
  const { loggedUserData } = UseAuth();

  const [filial, updateFilial] = useState<SelectOptionInterface[]>();
  const [companyObsoleto, updateCompanyObsoleto] = useState<SelectOptionInterface[]>();
  const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>();
  const [base, updateBase] = useState<SelectOptionInterface[]>();
  const [baseDesc, updateBaseDesc] = useState<SelectOptionInterface[]>();
  const [baseCode, updateBaseCode] = useState<SelectOptionInterface[]>();
  const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>();
  const [manufacturer, updateManufacturer] = useState<SelectOptionInterface[]>();
  const [campanhasAtivas, updateCampanhasAtivas] = useState<SelectOptionInterface[]>();
  const [listModelCompanies, updateListModelCompanies] = useState<SelectOptionInterface[]>([]);
  const [locationList, updateLocationList] = useState<SelectOptionInterface[]>([]);

  // Begin: Bloco que verificar permissões de acesso  
  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter(
      (header) => header.navTitle === 'Configurações',
    )[0].navItem.filter(
      (item) => item.title === 'Cadastrar Regras de Consultor Online',
    )[0].role;

    if (loggedUserData?.role) {
      loggedUserData.role.forEach((value) => {
        if (roleScreen.includes(value)) updateShowScreen(true);
      });
    }
  }, [loggedUserData]);
  // End: Bloco que verificar permissões de acesso

  /** planejamento */
  async function listByIdPlanning(): Promise<any> { }
  
  /** planejamento lista*/
  async function listAllPlanning(): Promise<any> {
      const response = await new RestUseCase(ENDPOINT_PLANNING!).Get();
      return handleRequest(response);
    }

  /** planejamento cria*/
  async function createPlanning(params?: any): Promise<any> {
      params.idEmpresa = loggedUserData?.companyId
      const response = await new RestUseCase(ENDPOINT_PLANNING!).Post({ data: params });
      return handleRequest(response);
    }

  /** planejamento edita*/
  async function editPlanning(params: {
      model: any;
      id: number
  }): Promise<any> {
      params.model.idEmpresa = loggedUserData?.companyId
      const response = await new RestUseCase(ENDPOINT_PLANNING! + '/' + params.id).Put({ data: params.model });
      return handleRequest(response);
    }

  /** planejamento remove*/
  async function removePlanning(params?: number): Promise < any >  {
      const response = await new RestUseCase(ENDPOINT_PLANNING! + '/' + params).Delete();
      return handleRequest(response);
  }

  const handleFilial = async () => {
    await new RestUseCase(`${ENDPOINT_FILIAL}?modulos=COMPRAS`).Get().then((response) => {
      updateFilial(
        response.body.map((value: any) => {
          return { value: value.id, label: value.nome };
        }),
      );
    });
  };
  const handleCompanyObsoleto = async () => {
    await new RestUseCase(ENDPOINT_COMPANIES_OBSOLETO).Get().then((response) => {
      updateCompanyObsoleto(
        response.body.map((value: any) => {
          return { value: value.id, label: value.nome };
        }),
      );
    });
  };
  const handleDiscountGroup = async () => {
    await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS).Get().then((response) => {
      updateDiscountGroup(
        response.body.map((value: any) => {
          return { value: value, label: value };
        }),
      );
    });
  };
  const handleRangeProfitability = async () => {
    await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
      updateRangeProfitability(
        response.body.map((value: any) => {
          return { value: value, label: value };
        }),
      );
    });
  };
  const handleBaseDesc = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE_DESC).Get().then((response) => {
      updateBaseDesc(
        response.body.descricoes.map((value: any) => {
          return {
            value: value,
            label: value,
          };
        }),
      );
    });
  };

  const handleBaseCode = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE_CODE).Get().then((response) => {
      updateBaseCode(
        response.body.codigos.map((value: any) => {
          return {
            value: value,
            label: value,
          };
        }),
      );
    });
  };


  const handleManufacturer = async () => {
    await new RestUseCase(ENDPOINT_MANUFACTURER).Get().then((response) => {
      updateManufacturer(
        response.body.map((value: any) => {
          return {
            value: value.codigo,
            label: `${value.codigo} - ${value.nome}`,
          };
        }),
      );
    });
  };
  const handleObservationItem = async (params: {
    codigo: number;
    filial: number
  }) => {
    const response = await new RestUseCase(`${END_POINT_ITEM}/${params.codigo}/observacoes?filiais=${params.filial}`).Get();
    return handleRequest(response);
  };
  const handleMovement = async (params: {
    codigoItem: number;
    idFilial: number;
    typeMoviment: 'compra' | 'venda'
  }) => {
    const response = await new RestUseCase(`${END_POINT_MOVEMENT}/${params.typeMoviment}?codigoItem=${params.codigoItem}&idFilial=${params.idFilial}`).Get();
    return handleRequest(response);
  };
  const correlate = async (params: {
    codigoItem: number
  }) => {
    const response = await new RestUseCase(`${END_POINT_CORRELATE}/?codigo=${params.codigoItem}`).Get();
    return handleRequest(response);
  };

  async function listLocations(): Promise<any> {
    await new RestUseCase(ENDPOINT_LOCATIONS).Get().then((response) => {
      if (response.statusCode !== 500 && response.statusCode !== undefined) {
        updateLocationList(
          response.body.locacoes.map((value: any) => {
            return { value: value.codigo, label: value.codigo };
          }),
        );
      }
    });
  }

  const handleCampanhasAtivas = async () => {
    await new RestUseCase(ENDPOINT_CAMPANHAS_ATIVAS).Get().then((response) => {
      updateCampanhasAtivas(
        response.body.map((value: any) => {
          return { value: value.id, label: value.descricao };
        }),
      );
    });
  };

  useEffect(() => {
    handleFilial();
    handleDiscountGroup();
    handleRangeProfitability();
    handleBaseDesc();
    handleBaseCode();
    handleManufacturer();
    handleCompanyObsoleto();
    handleCampanhasAtivas();
    listLocations();
  }, []);

  useEffect(() => {
    OnlineConsultatntFilterPlanningAdapter.map((value) => {
      if (value.label === 'Filial') value.field[1].optionsInput = filial;
      if (value.label === 'Grupo de desconto') value.field[1].optionsInput = discountGroup;
      if (value.label === 'Base') {
        value.field[1].optionsInput = baseCode;
      }
      if (value.label === 'Base Descrição') {
        value.field[1].optionsInput = baseDesc;
      }
      if (value.label === 'Lucratividade do fornecedor') value.field[1].optionsInput = rangeProfitability;
      if (value.label === 'Obsoleto') {
        value.field[1].optionsInput = companyObsoleto;
      }
      if (value.label === 'Código do Fabricante') value.field[1].optionsInput = manufacturer;
      if (value.label === 'Campanha ATIVA') value.field[1].optionsInput = campanhasAtivas;
      if (value.label === 'Locações') value.field[1].optionsInput = locationList;
    });

    OnlineConsultatntFilterPlanningAdapter.sort(function (a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });
  }, [filial, discountGroup, base, rangeProfitability, listModelCompanies, companyObsoleto, manufacturer, campanhasAtivas, baseDesc, baseCode, locationList]);

  
  return (
    <>    
      {showScreen ? (
        <RegraConsultorOnlinePage
          titlePage={titlePage}
          optionsFiltersDefault={OnlineConsultatntFilterPlanningAdapter}
          createPlanning={createPlanning}
          editPlanning={editPlanning}
          listAllPlanning={listAllPlanning}
          listByIdPlanning={listByIdPlanning}
          removePlanning={removePlanning}
        />            
      ) : (
        <ErrorNotAuthorizedPage />
      )}
      <Footer />
    </>
  );
};
