/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { useEffect, useState } from 'react'
import CrudPage from 'presentation/pages/crud-page'
import { UseAuth } from 'presentation/hook/auth-hook'
import { RestUseCase } from 'data/useCase/rest-usecase'
import { Button, Col, Form, Pagination, Row } from "react-bootstrap"
import { EntityField } from 'domain/entity/field/crud/field-crud-entity'
import { getParameterUrlByName, parseLinkHeader } from 'main/helper/request-helper'
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface'
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page'
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter'
import { getEnv } from 'main/helper/window-helper'

interface LinkHeader { first: string, last: string, next: string, prev: string }

export const AcessLogFactory: React.FC = () => {

    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_ACESS_LOG")!
    const ENDPOINT_COMPANY = getEnv("REACT_APP_END_POINT_COMPANIES")!
    const ENDPOINT_USERS = getEnv('REACT_APP_END_POINT_USER')!;

    /** TITULO DA PAGINA */
    const titlePage = "Consulta de Log de Carga"

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([])
    const [tableBody, updateTableBody] = useState([])

    /** FILTRO DA TABELA */
    const [listCompany, updateListCompany] = useState<SelectOptionInterface[]>([])
    const [listUsers, updateListUsers] = useState<SelectOptionInterface[]>([]);
    const [user, updateUser] = useState<SelectOptionInterface>();
    const [company, updateCompany] = useState<SelectOptionInterface>()
    const [dateInitial, updateDateInitial] = useState<string>()
    const [dateEnd, updateDateEnd] = useState<string>()

    function changeFilialSelected(companyOptions: SelectOptionInterface) {
        updateUser(undefined)
        listAllUserByCompanies(companyOptions);
        updateCompany(companyOptions)
    }
    function changeDateInitial(date: string) {
        updateDateInitial(date)
    }
    function changeDateEnd(date: string) {
        updateDateEnd(date)
    }
    function changeUserSelect(user: SelectOptionInterface) {
        updateUser(user);
    }

    /** PAGINACAO */
    const [firstPagination, updateFirstPagination] = useState<string>("")
    const [lastPagination, updateLastPagination] = useState<string>("")
    const [nextPagination, updateNextPagination] = useState<string>("")
    const [prevPagination, updatePrevPagination] = useState<string>("")
    const [pageDescription, updatePageDescription] = useState<string>("Primeira página")
    const onClickFirstPagination = async () => {
        const page: string = getParameterUrlByName("page", firstPagination)!
        const size: string = getParameterUrlByName("size", firstPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription(page)

    }
    const onClickLastPagination = async () => {
        const page: string = getParameterUrlByName("page", lastPagination)!
        const size: string = getParameterUrlByName("size", lastPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription("Última página")

    }
    const onClickNextPagination = async () => {
        const page: string = getParameterUrlByName("page", nextPagination)!
        const size: string = getParameterUrlByName("size", nextPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription(page)

    }
    const onClickPrevPagination = async () => {
        const page: string = getParameterUrlByName("page", prevPagination)!
        const size: string = getParameterUrlByName("size", prevPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription(page)

    }

    /** CHAMADA ENDPOINT */
    const getAll = async (pagination: { page: string, size: string }) => {

        let querie = `?page=${pagination.page}&size=${pagination.size}&`

        if (company?.value)
            querie += `empresa=${company?.value}&`

        if (user !== null && user !== undefined)
            querie += `usuario=${user.value}&`

        if (dateInitial)
            querie += `inicio=${dateInitial}&`

        if (dateEnd)
            querie += `fim=${dateEnd}&`

        await new RestUseCase(`${ENDPOINT_CRUD}${querie}`)
            .Get()
            .then(response => {
                if (response.headers["link"]) {
                    let links: LinkHeader = parseLinkHeader(response.headers["link"]) as any

                    updateFirstPagination(links.first)
                    updateLastPagination(links.last)
                    updateNextPagination(links.next)
                    updatePrevPagination(links.prev)
                }

                updateTableHeader(entityFields.map((value) => { return { name: value.name, labelTable: value.labelTable, align: value.align, columnVisible: value.tableField.columnVisible } }))
                updateTableBody(response.body.map((value: any) => {
                    return value
                }))
            })
    }
    const handleSelectCompany = async () => {
        await new RestUseCase(ENDPOINT_COMPANY)
            .Get()
            .then(response => {
                updateListCompany(response.body.map((value: any) => {
                    return { value: value.id, label: value.nome }
                }))
            })
    }
    async function listAllUserByCompanies(company: SelectOptionInterface): Promise<any> {
        let query = `?empresa=${company?.value}`;

        await new RestUseCase(ENDPOINT_USERS + query).Get()
            .then((response) => {
                updateListUsers(response.body.map((value: any) => {
                    return { value: value.id, label: value.nome };
                }));
            });
    }

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        { name: "data", labelField: "Data:", labelTable: "Data", typeField: "date", valueField: null, sizeField: 6, required: false, align: "center", tableField: { column: "Data", columnVisible: true } },
        { name: "idUsuario", labelField: "Id Usuário:", labelTable: "Id Usuário", typeField: "text", valueField: null, sizeField: 6, required: false, align: "center", tableField: { column: "Id Usuário", columnVisible: true } },
        { name: "nome", labelField: "Nome:", labelTable: "Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nome", columnVisible: true } },
        { name: "nomeUsuario", labelField: "Nome Usuário:", labelTable: "Nome Usuário", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nome Usuário", columnVisible: true } },
        { name: "quantidade", labelField: "Quantidade:", labelTable: "Quantidade", typeField: "text", valueField: null, sizeField: 6, required: false, align: "right", tableField: { column: "Quantidade", columnVisible: true } },
        { name: "status", labelField: "Status:", labelTable: "Status", typeField: "text", valueField: null, sizeField: 6, required: false, align: "center", tableField: { column: "Status", columnVisible: true } }
    ]
    const [entity, updateEntity] = useState(entityFields)

    useEffect(() => {
        handleSelectCompany()
    }, [])


    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Consultar Log Carga")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }

    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <CrudPage
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={() => { }}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    children={
                        {
                            filterTable:
                                <>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Label>Empresa:</Form.Label>
                                            <Select options={listCompany} onChange={val => { changeFilialSelected(val!) }} />
                                        </Col>

                                        <Col md={3}>
                                            <Form.Label>Início:</Form.Label>
                                            <Col>
                                                <Form.Control type="date" onChange={(event) => changeDateInitial(event.target.value)} />
                                            </Col>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Fim:</Form.Label>
                                            <Col>
                                                <Form.Control type="date" onChange={(event) => changeDateEnd(event.target.value)} />
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col md={6}>
                                            <Form.Label>Usuario:</Form.Label>
                                            <Select key={user?.value} options={listUsers} isDisabled={listUsers.length === 0}
                                                onChange={val => { changeUserSelect(val!) }} defaultValue={user} isClearable/>
                                        </Col>
                                    </Row>
                                    <div className='d-flex align-items-center justify-content-end mt-4'>
                                        <Button variant="primary" size="sm" className='mb-4'
                                            onClick={() => getAll({ page: "0", size: "50" })}
                                            disabled={company === null || company === undefined || dateInitial === null || dateInitial == undefined
                                                    || dateEnd === null || dateEnd === undefined}
                                        >
                                            Pesquisar
                                        </Button>
                                    </div>
                                </>,
                            pagination:
                                <>
                                    <Row className="d-flex align-items-center justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            <p><strong>{pageDescription}</strong></p>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <Pagination size="sm">
                                                <Pagination.First onClick={onClickFirstPagination} />
                                                <Pagination.Prev onClick={onClickPrevPagination} />
                                                <Pagination.Next onClick={onClickNextPagination} />
                                                <Pagination.Last onClick={onClickLastPagination} />
                                            </Pagination>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <></>
                                        </Col>
                                    </Row>
                                </>
                        }
                    }
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
