/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { UseAuth } from 'presentation/hook/auth-hook';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { handleRequest } from 'main/common/request-common';
import EqualizationPage from 'presentation/pages/analysis/equalization-page';
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter';
import { EqualizationFilterPlanningAdapter } from 'main/adapter/planning/equalization-filter-planning-adapter';
import { getLocalStorage } from 'data/cache/localstorage-cache';
import { ParseDateToBr, ParseDateToBrWithHours } from 'main/helper/format-date-helper';
import { getEnv } from 'main/helper/window-helper';
import { TableDescriptions } from '../../../../domain/interfaces/table-interface';
import { ColumsTableEqualizationAdapter } from 'main/adapter/columsTable/colums-table-equalization-adapter';
import { checkColumns } from 'main/helper/table-columns-helper';

interface ModelSave {
  tipo?: string;
  filialsRecebimento?: number[];
  filiaisTransferencia?: number[];
  itens?: any[];
  filtros?: any[];
}

export const EqualizationFactory: React.FC = () => {
  /**
   *
   *
   *
   *
   *  endpoint */
  const ENDPOINT_MANUFACTURER = getEnv('REACT_APP_END_POINT_MANUFACTURER')!;
  const ENDPOINT_DOWNLOAD = getEnv('REACT_APP_END_POINT_GENERATE_EQUALIZATION');
  const ENDPOINT_PLANNING = getEnv('REACT_APP_END_POINT_ANALYTICAL') + '/filtros';
  const ENDPOINT_TABLE = getEnv('REACT_APP_END_POINT_EQUALIZATION');
  const ENDPOINT_FILIAL = getEnv('REACT_APP_END_POINT_FILIAL')!;
  const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv('REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS')!;
  const END_POINT_ITEM_TRACKS = getEnv('REACT_APP_END_POINT_ITEM_TRACKS')!;
  const END_POINT_ITEM_BASE = getEnv('REACT_APP_END_POINT_ITEM_BASE')!;
  const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_EQUALIZATION')!;
  const LOCALSTORAGE_COLUMS_ORDER = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION')! + '_order';
  const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION')!;
  const ENDPOINT_LOCATIONS = getEnv('REACT_APP_END_POINT_LOCATIONS');

  /**
   *
   *
   *
   *
   *  verificar as permissões de acesso */
  const { loggedUserData } = UseAuth();
  const [showScreen, updateShowScreen] = useState<boolean>(false);
  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter((header) => header.navTitle === 'Análise')[0].navItem.filter((item) => item.title === 'Equalização')[0].role;

    if (loggedUserData?.role) {
      loggedUserData.role.forEach((value) => {
        if (roleScreen.includes(value)) updateShowScreen(true);
      });
    }
  }, [loggedUserData]);

  /**
   *
   *
   *
   *
   *  selects */
  const [filial, updateFilial] = useState<SelectOptionInterface[]>();
  const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>();
  const [base, updateBase] = useState<SelectOptionInterface[]>();
  const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>();
  const [manufacturer, updateManufacturer] = useState<SelectOptionInterface[]>();
  const [locationList, updateLocationList] = useState<SelectOptionInterface[]>([]);

  /**
   *
   *
   *
   *
   *  chamada ao backend */
  const paginationDefault = '?page=0&size=50';
  const END_POINT_LAST_CHARGE = getEnv('REACT_APP_END_POINT_LAST_CHARGE')!;
  const [lastCharge, updateLastCharge] = useState<string>();

  const handleLastCharge = async () => {
    await new RestUseCase(END_POINT_LAST_CHARGE).Get().then((response) => {
      updateLastCharge(ParseDateToBrWithHours(response.body));
    });
  };
  const handleFilial = async () => {
    await new RestUseCase(`${ENDPOINT_FILIAL}?modulos=COMPRAS`).Get().then((response) => {
      updateFilial(
        response.body.map((value: any) => {
          return { value: value.id, label: value.nome };
        })
      );
    });
  };

  const handleDiscountGroup = async () => {
    await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS).Get().then((response) => {
      updateDiscountGroup(
        response.body.map((value: any) => {
          return { value: value, label: value };
        })
      );
    });
  };
  const handleRangeProfitability = async () => {
    await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
      updateRangeProfitability(
        response.body.map((value: any) => {
          return { value: value, label: value };
        })
      );
    });
  };
  const handleBase = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE).Get().then((response) => {
      updateBase(
        response.body.bases.map((value: any) => {
          return { value: value.codigo, label: `${value.codigo} - ${value.desc}` };
        })
      );
    });
  };
  const [baseDesc, updateBaseDesc] = useState<SelectOptionInterface[]>();
  const END_POINT_ITEM_BASE_DESC = getEnv('REACT_APP_END_POINT_ITEM_BASE_DESC')!;
  const handleBaseDesc = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE_DESC).Get().then((response) => {
      updateBaseDesc(
        response.body.descricoes.map((value: any) => {
          console.log(value);
          return {
            value: value,
            label: value,
          };
        }),
      );
    });
  };
  const handleManufacturer = async () => {
    await new RestUseCase(ENDPOINT_MANUFACTURER).Get().then((response) => {
      updateManufacturer(
        response.body.map((value: any) => {
          return {
            value: value.codigo,
            label: `${value.codigo} - ${value.nome}`,
          };
        })
      );
    });
  };
  const resume = async (params: any) => {
    const resume = await new RestUseCase(ENDPOINT_TABLE! + '/resumo').Post({
      data: params,
    });

    updatePanelInfo([
      [
        {
          label: 'Quantidade Compra Sugerido',
          number: resume.body.quantidadeCompraSugerido,
        },
        {
          label: 'Quantidade Compra Salvo',
          number: resume.body.quantidadeCompraSalvo,
        },
      ],
      [
        {
          label: 'Valor Compra Sugerido',
          number: resume.body.valorCompraSugerido.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        },
        {
          label: 'Valor Compra Salvo',
          number: resume.body.valorCompraSalvo.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        },
      ],
      [
        {
          label: 'Quantidade Transferência Sugerido',
          number: resume.body.quantidadeTransferenciaSugerido,
        },
        {
          label: 'Quantidade Transferência Salvo',
          number: resume.body.quantidadeTransferenciaSalvo,
        },
      ],
      [
        {
          label: 'Valor Transferência Sugerido',
          number: resume.body.valorTransferenciaSugerido.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        },
        {
          label: 'Valor Transferência Salvo',
          number: resume.body.valorTransferenciaSalvo.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        },
      ],
      [
        {
          label: 'Valor Pedido Sugerido',
          number: resume.body.valorPedidoSugerido.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        },
        {
          label: 'Valor Pedido Salvo',
          number: resume.body.valorPedidoSalvo.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        },
      ],
    ]);
  };
  const ENDPOINT_TIPO_REMANEJAMENTO = getEnv('REACT_APP_END_POINT_EQUALIZATION')!;
  const [typeRemanejamento, updateTypeRemanejamento] = useState<{
    dataPesquisa: string;
    tipo: string;
  }>({ dataPesquisa: '', tipo: '' });

  async function handleTypeRemanejamento(): Promise<any> {
    await new RestUseCase(ENDPOINT_TIPO_REMANEJAMENTO).Get().then((response) => {
      if (response.statusCode !== 500 && response.statusCode !== undefined) {
        if (response.body !== '') {
          updateTypeRemanejamento({
            dataPesquisa: ParseDateToBr(response.body.dataPesquisa),
            tipo: response.body.tipo,
          });
        }
      }
    });
  }

  async function listLocations(): Promise<any> {
    await new RestUseCase(ENDPOINT_LOCATIONS).Get().then((response) => {
      if (response.statusCode !== 500 && response.statusCode !== undefined) {
        updateLocationList(
          response.body.locacoes.map((value: any) => {
            return { value: value.codigo, label: value.codigo };
          }),
        );
      }
    });
  }

  /**
   *
   *
   *
   *
   *  painel */
  const [panelInfo, updatePanelInfo] = useState<any[]>([]);

  /**
   *
   *
   *
   *
   *  gerenciador de estado */
  useEffect(() => {
    checkColumns(LOCALSTORAGE_COLUMS, ColumsTableEqualizationAdapter);
    handleFilial();
    handleDiscountGroup();
    handleRangeProfitability();
    handleBase();
    handleBaseDesc()
    handleManufacturer();
    handleLastCharge();
    handleTypeRemanejamento();
    listLocations();
  }, []);
  useEffect(() => {
    EqualizationFilterPlanningAdapter.map((value) => {
      if (value.label === 'Filial') value.field[1].optionsInput = filial;
      if (value.label === 'Grupo de desconto') value.field[1].optionsInput = discountGroup;
      if (value.label === 'Base') {
        value.field[1].optionsInput = base;
      }
      if (value.label === 'Lucratividade do fornecedor') value.field[1].optionsInput = rangeProfitability;
      if (value.label === 'Código do Fabricante') value.field[1].optionsInput = manufacturer;
      if (value.label === 'Base Descrição') {
        value.field[1].optionsInput = baseDesc;
      }
      if (value.label === 'Locações') value.field[1].optionsInput = locationList;
    });

    EqualizationFilterPlanningAdapter.sort(function (a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });
  }, [filial, discountGroup, base, rangeProfitability, locationList]);

  return (
    <>
      {showScreen ? (
        <EqualizationPage
          /** dados / confi para o planejamento */
          optionsFiltersDefault={EqualizationFilterPlanningAdapter}
          /** processa e lista */
          processAndList={async function (params: { pagination: string; model?: ModelSave }): Promise<any> {
            if (!params.model?.filtros) {
              params.model!.filtros = getLocalStorage(LOCALSTORAGE_PLANNING)! as any[];
            }

            const response = await new RestUseCase(ENDPOINT_TABLE! + (params?.pagination === undefined ? paginationDefault : params?.pagination)).Put({
              headers: { 'Action-Name': "Equalizacao/Processar" },
              data: params.model,
            });

            await resume(params.model?.filtros);

            await handleTypeRemanejamento();

            return handleRequest(response);
          }}
          /** lista */
          list={async function (params: { pagination: string; model?: any }): Promise<any> {
            /** cria a query */
            let localStorageColumsOrder: any[] = getLocalStorage(LOCALSTORAGE_COLUMS_ORDER) ?? [];

            let queryParamsOrder = '';

            localStorageColumsOrder.forEach((fe) => {
              if (fe.ordinationValue) {
                queryParamsOrder += `&orders=${fe.ordinationName.toLowerCase()}:${fe.ordinationValue}`;
              }
            });
            var dataPlanning = params.model ? params.model : getLocalStorage(LOCALSTORAGE_PLANNING);

            const response = await new RestUseCase(
              ENDPOINT_TABLE! + (params?.pagination === undefined ? paginationDefault + queryParamsOrder : params?.pagination + queryParamsOrder)
            ).Post({
              data: dataPlanning ? dataPlanning : undefined,
            });

            await resume(dataPlanning ? dataPlanning : undefined);

            await handleTypeRemanejamento();

            return handleRequest(response);
          }}
          /** salva a pagina ou linha */
          save={async function (params: { modelItem: [{ filial: number; id: number; quantidade: number }] }): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_TABLE!).Patch({ data: params });
            return handleRequest(response);
          }}
          /** salva tudo */
          saveAll={async function (params: any): Promise<any> {
            params.filtros = [];
            const response = await new RestUseCase(ENDPOINT_TABLE! + `/all`).Patch({ data: params });
            return handleRequest(response);
          }}
          /** remove a pagina ou linha */
          remove={async function (params: { modelItem: [{ filial: number; id: number }] }): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_TABLE!).Delete({ data: params });
            return handleRequest(response);
          }}
          /** planejamento */
          listByIdPlanning={async function (): Promise<any> {}}
          /** planejamento lista*/
          listAllPlanning={async function (): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_PLANNING!).Get();
            return handleRequest(response);
          }}
          /** planejamento cria*/
          createPlanning={async function (params?: any): Promise<any> {
            params.idEmpresa = loggedUserData?.companyId
            const response = await new RestUseCase(ENDPOINT_PLANNING!).Post({ data: params });
            return handleRequest(response);
          }}
          /** planejamento edita*/
          editPlanning={async function (params: {
            model: any;
            id: number;
          }): Promise<any> {
            params.model.idEmpresa = loggedUserData?.companyId
            const response = await new RestUseCase(ENDPOINT_PLANNING! + '/' + params.id).Put({ data: params.model });
            return handleRequest(response);
          }}
          /** planejamento remove*/
          removePlanning={async function (params?: number): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_PLANNING! + '/' + params).Delete();
            return handleRequest(response);
          }}
          /** exporta arquivo */
          exportFileCSV={async function (): Promise<any> {
            var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING);
            const response = await new RestUseCase(ENDPOINT_TABLE!).Post({
              headers: { accept: 'text/csv' },
              data: localStoragePlanning ? localStoragePlanning : undefined,
            });
            return handleRequest(response);
          }}
          exportFileExcel={async function (): Promise<any> {
            var localStorageColunas: TableDescriptions[] = getLocalStorage(LOCALSTORAGE_COLUMS);

            let querie = '';

            if (localStorageColunas !== null) {
              let colunsParams = localStorageColunas
                .sort((a, b) => a.order - b.order)
                .filter((item) => item.hidden === false)
                .map((item) => item.text);

              colunsParams.forEach((item: any) => (querie += `&filtroColunas=${item}`));
            }

            var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING);

            const response = await new RestUseCase(`${ENDPOINT_TABLE!}/gerar-excel${querie === '' ? querie : '?' + querie}`).Post({
              responseType: 'blob',
              data: localStoragePlanning ? localStoragePlanning : undefined,
            });
            return handleRequest(response);
          }}
          /** gera o equalizacao */
          generateEqualization={async function (): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_DOWNLOAD! + '/gerar-remanejamento').Post({ responseType: 'blob' });
            return handleRequest(response);
          }}
          /** role do usuário logado */
          loggedUserDataRole={loggedUserData?.role!}
          /** painel com os totalizadores */
          panelList={panelInfo}
          /** remove tudo */
          removeAll={async function (params: any): Promise<any> {
            params.filtros = [];
            const response = await new RestUseCase(ENDPOINT_TABLE! + `/all`).Delete({ data: params });
            return handleRequest(response);
          }}
          lastCharge={lastCharge!}
          typeRemanejamento={typeRemanejamento}
        />
      ) : (
        <ErrorNotAuthorizedPage />
      )}
    </>
  );
};
